<template>
  <v-container mt-flex fluid>
    <v-container d-flex d-sm-none>
      <v-row>
        <v-col cols="12" class="pa-5"> </v-col>
      </v-row>
    </v-container>
    <v-row class="pa-5 bg-white">
      <h1><strong>DM Dance</strong> Enrolment Form 2024</h1>
    </v-row>
    <v-row class="pa-5 bg-grey">
      <v-col>
        To view Dance M dance uniform pricing please click
        <router-link to="/uniform-options">here</router-link>.
      </v-col>
    </v-row>
    <v-row class="pa-5 bg-grey">
      <v-col>
        <iframe
          id="JotFormIFrame-240162542993559"
          title="DM Dance - Potchefstroom"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation;"
          src="https://form.jotform.com/240162542993559"
          frameborder="0"
          style="min-width: 100%; height: 1050px; border: none"
          scrolling="no"
        >
        </iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
import jotfom from "../assets/jotform.js";
</script>
<style>
.view-enter-active {
  animation-duration: 1.5s;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-delay: 1s ease-in;
}

@keyframes slideIn {
  from {
    opacity: 0;
    margin-left: -100%;
  }

  to {
    opacity: 1;
    margin-left: 0%;
  }
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-card.on-hover.theme--dark {
  transition: background-color 0.5s ease;
  background-color: rgba(#fff, 0.8);
}
</style>